$color-primary: #0060AE;
$flip-duration: 1s;

.home {
    --color-primary: #{$color-primary};

    .header {
        background: linear-gradient(153deg, #46597e 0%, $color-primary 50%, #344d7c 100%);
        color: white;
        height: 500px;
        position: relative;

        >.container {
            .eh-logo {
                img {
                    max-height: 36px;
                }

                &-list {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-top: 70px;
                }

                &--primary {
                    padding-left: 15px;

                    img {
                        max-height: 64px;
                    }
                }

                &--secondary {
                    padding-right: 15px;
                }
            }

            .logo-first {
                display: flex;
                align-items: center;
            }

            .lang-list {
                margin-left: 3rem;
            }


            .title-section {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                text-align: center;
                padding: 0 35px;
            }

            h2.title {
                text-align: center;
                font-weight: bold;
                color: white;
            }
        }
    }

    .lang {
        img {
            margin: 5px;
            height: 20px;
        }
    }

    .footer {
        background: $color-primary;

        >.container {
            padding: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            .eh-logo {
                padding-left: 15px;
            }
        }
    }

    .instructions {
        text-align: center;
        padding-bottom: 4rem;

        .title {
            z-index: 2;
        }

        .section-get-started {
            margin-bottom: 4rem;
        }
    }

    .instruction {
        &__list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__item {
            flex: 1 1 0;
        }

        &__icon {
            width: 72px;
            height: 72px;
            background-repeat: no-repeat;
            background-position: center center;
            border: 4px solid $color-primary;
            border-radius: 100%;
            margin: 0 auto 17px;
            display: flex;
            align-items: center;
            justify-content: center;

            >img {
                width: 40px;
                height: 40px;
            }
        }

        &__title {
            margin-bottom: 1.5rem;
        }

        &__desc {
            margin: 0;
        }
    }

    .media-box {
        padding: 100px 0;
        position: relative;

        .voice-tool-area {
            position: relative;
            margin: 0 auto;
            width: 428px;
            height: 720px;

            .back {
                border: 1px solid rgba(0, 0, 0, 0.075);
                background-color: white;
            }
        }

        .voice-tool {
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }

        .results {

            .error>* {
                color: tomato !important;
            }

            .success {
                text-align: center;

                h1 {
                    background-color: var(--color-primary);
                    border-radius: 50%;
                    color: white;
                    line-height: 14rem;
                    height: 14rem;
                    width: 14rem;
                    margin: 0 auto;
                    text-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
                }

                .stress-result {
                    &.level-0 h1 {
                        background-color: green;
                    }

                    &.level-1 h1 {
                        background-color: yellow;
                    }

                    &.level-2 h1 {
                        background-color: red;
                    }
                }

                .anxiety-result,
                .depression-result {
                    &.level-0 h1 {
                        background-color: green;
                    }

                    &.level-1 h1 {
                        background-color: yellow;
                    }

                    &.level-2 h1 {
                        background-color: orange;
                    }

                    &.level-3 h1 {
                        background-color: red;
                    }
                }

                .note {
                    color: tomato;
                    font-size: 2rem;
                    font-weight: bold;
                    margin: 1rem 0 2rem;
                }
            }

            .anxiety-result,
            .depression-result {
                margin-top: 4rem;
            }
        }

        &--finished {
            .voice-tool-area {
                height: 75rem;
            }
        }
    }

    &.device-desktop,
    &.voice-tool-started {
        .media-box .circle {
            z-index: -1;
        }
    }
}

.container {
    max-width: 1600px;
    box-sizing: border-box;
    padding: 0 35px;
    margin: 0 auto;
}

.circle {
    background-repeat: no-repeat;
    background-position: center center;
}

.e-42 {
    background-image: url(../../assets/img/ellipse-42.svg);
    width: 109px;
    height: 109px;
    position: absolute;
    bottom: -40px;
    left: 25%;
}

.e-45 {
    background-image: url(../../assets/img/ellipse-45.svg);
    width: 30px;
    height: 30px;
    position: absolute;
    left: 34%;
    bottom: 55px;
}

.e-46 {
    background-image: url(../../assets/img/ellipse-46.svg);
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: -100px;
    left: 32%;
}

.e-47 {
    background-image: url(../../assets/img/ellipse-47.svg);
    width: 172px;
    height: 172px;
    position: absolute;
    top: 45%;
    left: calc(50% + 15rem);
    z-index: -1;
}

.e-44 {
    background-image: url(../../assets/img/ellipse-44.svg);
    width: 50px;
    height: 50px;
    position: absolute;
    top: 70%;
    left: calc(50% + 30rem);
}

.voice-tool-started .voice-tool {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.075);
}


@media screen and (max-width: 768px) {
    .circle {
        display: none;
    }

    .home {
        .instructions {
            .title {
                margin-bottom: 5rem;
            }
        }

        .instruction {
            &__list {
                flex-direction: column;
            }

            &__item {
                margin-bottom: 5rem;
            }
        }

        .media-box {
            display: none;
        }

        .header {
            height: 400px;

            >.container {
                .eh-logo {
                    padding-left: 0;
                    padding-right: 0;

                    &-list {
                        padding-top: 36px;
                    }

                    &--primary {
                        img {
                            max-height: 48px;
                        }
                    }
                }

                .logo-first {
                    flex-direction: column;
                    flex-direction: column-reverse;
                }

                .lang-list {
                    margin-left: 0;
                    margin-bottom: 2rem;
                }

                .title-section {
                    margin-top: 4rem;
                }
            }
        }
    }


    &.device-desktop,
    &.voice-tool-started {

        .media-box {
            display: unset;

            .voice-tool-area {
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }

    &.voice-tool-finished {
        .home {
            .section-get-started {
                display: none;
            }
        }

        .media-box {
            display: unset;

            .voice-tool-area {
                display: block;
                width: auto;
                height: 75rem;
                margin-bottom: 4rem;

                .back {
                    border: none;
                }
            }
        }
    }
}

.flipper {
    perspective: 2000px;

    &__area {
        height: 100%;
        width: 100%;
        transition: transform $flip-duration linear;
        transform-style: preserve-3d;
        position: relative;

        .front,
        .back {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            transition-delay: $flip-duration/2;

            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            visibility: visible;
        }

        .back {
            transform: rotateY(180deg);
            visibility: hidden;
        }
    }

    &.flipped &__area {
        transform: rotateY(180deg);

        .front {
            visibility: hidden;
        }

        .back {
            visibility: visible;
        }
    }
}